import styled from 'styled-components';

export const BlueContainer = styled.div`
  background: #0f71ba;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 65px 0px 0px 0px;
  text-align: center;
  @media (max-width: 991px) {
    width: 100%;
    height: auto;
    padding: 45px 35px 10px 35px;
    text-align: center;
  }
`;

export const ImageStyle = styled.img`
  max-width: 500px;
  height: auto;
  padding-bottom: 60px;
  border: 0;
  vertical-align: top;
  @media (max-width: 991px) {
    display: block;
    max-width: 310px;
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 30px;
    margin: 0 auto;
  }
`;
